$primary-color: #007bff; // або будь-який інший колір, який ви хочете використовувати як основний
$black: #000;
$white: #fff;
$gray: #3B413C;
$green: #94D1BE;
$light-gray: #9DB5B2;
$light-green: #DAF0EE;
$red: #A51111;

@mixin text($size, $weight, $height) {
    font-size: $size;
    font-weight: $weight;
    line-height: $height;
}

@mixin title(){
    @include text(60px, 700, 90px);
    @media screen and (max-width: 1140px){
        @include text(50px, 700, 70px);
    }
    @media screen and (max-width: 991px){
        @include text(40px, 700, 65px);
    }
    @media screen and (max-width: 676px){
        @include text(35px, 700, 50px);
    }
}

@mixin title2(){
    @include text(32px, 500, 54px);
    margin: 0;

    @media screen and (max-width: 1140px){
        @include text(30px, 500, 50px);
    }

    @media screen and (max-width: 991px){
        @include text(28px, 500, 40px);
    }
    @media screen and (max-width: 676px){
        @include text(24px, 500, 30px);
    }
}

@mixin simpleText(){
    @include text(20px, 400, 33px);
    margin: 0;

    @media screen and (max-width: 991px){
        @include text(18px, 400, 28px);
    }
}

@mixin button(){
    display: inline-block;
    background-color: $gray;
    border-radius: 10px;
    border: 1px solid $light-gray;
    text-transform: uppercase;
    text-decoration: none;
    color: $white;
    @include text(20px, 400, 33px);
    padding: 15px 0;
    width: 50%;
    text-align: center;
    outline: none;
    cursor: pointer;

    &:hover{
        background-color: darken($gray, 10%);
    }

    @media screen and (max-width: 676px){
        @include text(18px, 400, 28px);
    }
}

