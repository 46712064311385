@import '../../variable.scss';

$primary-color: #333333;
$secondary-color: #777777;
$background-color: #ebdddd;
$surface-color: #f1eaeac2;
$accent-color: #6B8E23;
$accent-hover-color: darken($accent-color, 10%);
$text-color-light: #dbcccc;
$review-background-color: $background-color; // Повертаємо фон секції відгуків до фону сторінки


.cart{
    padding: 40px 0;
    background-color: $background-color;

    .container {
        max-width: 1100px;
        margin: 0 auto;
        padding: 0 15px;
    }

    &__wrapper{
        display: flex;
        gap: 25px;
        margin-bottom: 30px;
        @media (max-width: 767px) {
            flex-direction: column;
            margin-bottom: 20px;
        }
    }

    &__photos{
        width: 50%;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 0 3px rgba($primary-color, 0.02); // Зменшено розмиття та прозорість
        background-color: $surface-color;
        padding: 15px;
        @media (max-width: 767px) {
            width: 100%;
            padding: 10px;
        }
    }

    &__main-photo{
        margin-bottom: 10px;
        .product-swiper {
            border-radius: 8px;
            overflow: hidden;
            .swiper-button-prev, .swiper-button-next {
                color: $secondary-color;
                background-color: rgba($surface-color, 0.7);
                border-radius: 50%;
                width: 35px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: calc(0px - var(--swiper-navigation-size)/2);
                transition: background-color 0.3s ease, color 0.3s ease;
                &::after {
                    font-size: 14px;
                    font-weight: bold;
                }
                &:hover {
                    background-color: rgba($surface-color, 0.9);
                    color: $primary-color;
                }
            }
        }

        .cart__img{
            border-radius: 8px;
            display: block;
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }

    &__thumbs {
        .thumbs-swiper {
            .swiper-slide {
                cursor: pointer;
                opacity: 0.4;
                transition: opacity 0.3s ease;
                &.swiper-slide-thumb-active {
                    opacity: 1;
                }
                .cart__thumb-wrapper {
                    border-radius: 6px;
                    overflow: hidden;
                    border: 1px solid transparent;
                    transition: border-color 0.3s ease, box-shadow 0.3s ease;
                    &:hover {
                        border-color: $secondary-color;
                        box-shadow: 0 0 0 2px rgba($secondary-color, 0.3);
                    }
                }
            }
        }
        .cart__img-thumb{
            border-radius: 6px;
            width: 100%;
            height: auto;
            display: block;
            object-fit: cover;
            aspect-ratio: 1 / 1;
        }
    }


    &__data{
        width: 50%;
        padding: 25px;
        border-radius: 10px;
        background-color: $surface-color;
        box-shadow: 0 0 3px rgba($primary-color, 0.02); // Зменшено розмиття та прозорість
        // border: 1px solid #EEEEEE;  // Видаляємо рамку
        @media (max-width: 767px) {
            width: 100%;
            padding: 15px;
        }
    }

    &__title{
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 8px;
        color: $primary-color;
    }

    &__brand{
        font-size: 1rem;
        color: $secondary-color;
        margin-bottom: 15px;
        display: block;
    }

    &__prices{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        flex-wrap: wrap;
    }

    &__price {
        font-size: 1.6rem;
        font-weight: 600;
        color: $primary-color;
        margin-right: 12px;
        span {
            font-size: 0.9rem;
            color: $secondary-color;
            margin-left: 3px;
        }
        &.cart__old-price {
            color: $secondary-color;
            text-decoration: line-through;
            font-weight: 500;
            font-size: 1.2rem;
        }
    }
    .sale-countdown {
        display: flex;
        align-items: center;
        margin-top: 8px;
        .sale-label {
            background-color: #D53F00;
            color: $surface-color;
            padding: 5px 8px;
            font-size: 0.8rem;
            font-weight: 700;
            border-radius: 4px;
            margin-right: 8px;
        }
        span {
            font-size: 0.8rem;
            color: $primary-color;
            margin-right: 4px;
            &:last-child {
                margin-right: 0;
            }
        }
    }


    &__sizes-section {
        margin-bottom: 20px;
    }

    &__text{
        font-size: 0.9rem;
        color: $primary-color;
        margin-bottom: 8px;
        display: block;
    }

    &__sizes{
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
    }

    &__size-item {
        position: relative;
    }

    &__radio{
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + label{
            border-color: $accent-color;
            color: $primary-color; // Змінено колір тексту на темно-сірий при виборі
            font-weight: 600;
            background-color: rgba($accent-color, 0.15); // Додано світлий фоновий колір при виборі
        }
    }

    &__size{
        display: block;
        padding: 8px 12px;
        border: 1px solid $secondary-color;
        border-radius: 6px;
        text-align: center;
        cursor: pointer;
        transition: border-color 0.3s, color 0.3s, font-weight 0.3s, background-color 0.3s, transform 0.3s; // Додано transition для background-color і transform
        font-size: 0.8rem;
        color: $primary-color;

        &:hover {
            border-color: $primary-color;
            // transform: scale(1.05); // Видаляємо збільшення при наведенні
        }
    }


    &__basket{
        @include button();
        width: 100%;
        padding: 10px 18px;
        font-size: 0.9rem;
        border-radius: 6px;
        background-color: $accent-color;
        color: $surface-color;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: $accent-hover-color;
        }
    }
}

.description{
    padding: 40px 0;
    background-color: $surface-color;
    border-bottom: 1px solid #EEEEEE;

    .container {
        max-width: 850px;
        margin: 0 auto;
        padding: 0 15px;
    }

    &__title{
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: 15px;
        color: $primary-color;
        text-align: center;
    }

    &__text{
        font-size: 0.9rem;
        color: $primary-color;
        line-height: 1.6;
        padding: 0 8px;
    }
}

.detail{
    padding: 40px 0;
    background-color: $surface-color;
    border-bottom: 1px solid #EEEEEE;

    .container {
        max-width: 850px;
        margin: 0 auto;
        padding: 0 15px;
    }

    &__title{
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: 20px; // Збільшено відступ знизу
        color: $primary-color;
        text-align: center;
    }

    &__wrapper{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); // Адаптивна сітка, мінімальна ширина колонки зменшена
        gap: 15px 20px; // Вертикальний та горизонтальний gap
        margin-top: 20px; // Збільшено відступ зверху
    }

    &__item{
        padding: 15px; // Збільшено padding елементу деталі
        border-radius: 8px; // Заокруглення кутів для блоків деталей
        background-color: #dbd9d9; // Білий фон для блоків деталей
        box-shadow: 0 2px 5px rgba($primary-color, 0.04); // Легка тінь для блоків деталей
        transition: box-shadow 0.3s ease; // Плавна тінь при наведенні

        &:hover {
            box-shadow: 0 4px 10px rgba($primary-color, 0.08); // Посилення тіні при наведенні
        }
    }

    &__name{
        display: block; // Ім'я деталі на новому рядку
        font-size: 0.9rem;
        color: $secondary-color;
        font-weight: 500;
        margin-bottom: 5px; // Відступ між ім'ям та значенням
    }

    &__text{
        display: block; // Значення деталі на новому рядку
        font-size: 0.95rem; // Трохи збільшено розмір шрифту значення
        color: $primary-color;
        word-wrap: break-word; // Перенос довгих слів
    }
}

.review{
    padding: 50px 0;
    background-color: $review-background-color; // Використовуємо фон сторінки
    border-top: 1px solid #EEEEEE; // Додаємо тонку верхню межу для розділення

    .container {
        max-width: 850px;
        margin: 0 auto;
        padding: 0 15px;
    }

    &__header-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        flex-wrap: wrap;
        gap: 10px;
    }

    &__title{
        font-size: 1.6rem;
        font-weight: 600;
        color: $primary-color;
        margin-bottom: 0;
    }

    &__new-open{
        @include button();
        padding: 8px 15px;
        font-size: 0.8rem;
        border-radius: 6px;
        background-color: transparent;
        color: $accent-color;
        border: 1.5px solid $accent-color;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s, border-color 0.3s;

        &:hover {
            background-color: $accent-color;
            color: $surface-color;
            border-color: $accent-color;
        }
    }

    &__new{
        position: relative;
        max-width: 550px;
        margin: 20px auto 0;
        padding: 25px;
        background-color: $surface-color;
        border-radius: 10px;
        box-shadow: 0 0 3px rgba($primary-color, 0.02); // Зменшено розмиття та прозорість
    }
    &__form-title {
        font-size: 1.4rem;
        font-weight: 600;
        color: $primary-color;
        text-align: center;
        margin-bottom: 15px;
    }

    &__content{
        width: 100%;
        padding: 12px;
        border: 1px solid $secondary-color;
        border-radius: 6px;
        margin-bottom: 12px;
        font-size: 0.9rem;
        resize: vertical;
        min-height: 80px;
        color: $primary-color;
        &::placeholder {
            color: $secondary-color;
        }
        &:focus {
            outline-color: $accent-color;
        }
    }
    &__buttons {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
    }

    &__save{
        @include button();
        padding: 8px 15px;
        font-size: 0.8rem;
        border-radius: 6px;
        background-color: $accent-color;
        color: $surface-color;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: $accent-hover-color;
        }
    }

    &__close{
        background: none;
        border: none;
        cursor: pointer;
        svg {
            width: 18px;
            height: 18px;
            path {
                fill: $secondary-color;
            }
        }
        &:hover svg path {
            fill: $primary-color;
        }
    }
    &__error{
        color: #D53F00;
        font-size: 0.8rem;
        margin-bottom: 8px;
        text-align: left;
    }


    &__item{
        padding: 18px;
        margin-bottom: 15px;
        background-color: $surface-color;
        border-radius: 10px;
        box-shadow: 0 0 3px rgba($primary-color, 0.02); // Зменшено розмиття та прозорість
    }
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        flex-wrap: wrap;
        gap: 8px;
    }

    &__user-name{
        font-size: 0.9rem;
        font-weight: 600;
        color: $primary-color;
        margin-bottom: 3px;
    }

    &__stars{
        display: flex;
        .star-icon {
            width: 14px; // Зменшено розмір іконок зірок у відгуках (ще менше)
            height: auto;
            margin-right: 2px;
            &.outline path {
                fill: $secondary-color;
            }
        }
    }

    &__text{
        font-size: 0.8rem; // Трохи збільшено розмір шрифту тексту відгуку
        color: $primary-color;
        line-height: 1.6; // Збільшено міжрядковий інтервал
        margin-bottom: 8px;
    }

    &__date{
        font-size: 0.7rem;
        color: $secondary-color;
        text-align: right;
    }

    &__more{
        @include button();
        display: block;
        margin: 15px auto 0;
        padding: 8px 15px;
        font-size: 0.8rem;
        border-radius: 6px;
        background-color: transparent;
        color: $accent-color;
        border: 1.5px solid $accent-color;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s, border-color 0.3s;

        &:hover {
            background-color: $accent-color;
            color: $surface-color;
            border-color: $accent-color;
        }
    }
}

.recommend{
    padding: 50px 0;
    background-color: $background-color;

    .container {
        max-width: 1100px;
        margin: 0 auto;
        padding: 0 15px;
    }

    &__title{
        font-size: 1.6rem;
        font-weight: 600;
        color: $primary-color;
        margin-bottom: 25px;
        text-align: center;
    }

    &__slider {
        margin-top: 15px;
    }
    .sale__wrapper {
        // Стилі слайдера рекомендацій можна додати тут, якщо потрібно
    }
}


.rating{
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin-bottom: 12px;
    &__radio{
        display: none;
    }

    &__star{
        cursor: pointer;
        margin-right: 3px;

        .star-icon {
            width: 18px; // Зменшено розмір іконок зірок рейтингу
            height: auto;
            path{
                fill: rgba($secondary-color, 0.3);
            }
        }
    }

    & > input:checked ~ label .star-icon path {
        fill: #FFD700;
    }

    &:not(:checked) > label:hover .star-icon path,
    &:not(:checked) > label:hover ~ label .star-icon path{
        fill: rgba(#FFD700, 0.5);
    }

    & > input:checked > label:hover ~ label .star-icon path,
    & > input:checked + label:hover ~ label .star-icon path,
    & > input:checked ~ label:hover ~ label .star-icon path,
    & > label:hover ~ input:checked ~ label .star-icon path{
        fill: #FFD700;
    }
}