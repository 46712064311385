@import '../../variable';

.choboti {

}
// Загальні стилі
.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  // Стилі для хедера (не змінюються)
  .header {
    background: linear-gradient(135deg, #41b073 0%, #8a3495 100%);
    padding: 60px 0;
    border-radius: 0 0 30px 30px;
    margin-bottom: 40px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
      flex-wrap: wrap; /* Додано для перенесення на менших екранах */
    }
  
    &__content {
      flex: 1;
      max-width: 50%; /* Обмежуємо максимальну ширину контенту */
    }
  
    &__title {
      font-size: 42px;
      font-weight: 700;
      margin: 0 0 15px;
      color: $primary-color;
    }
  
    &__subtitle {
      font-size: 24px;
      font-weight: 500;
      margin: 0 0 15px;
      color: #333;
    }
  
    &__text {
      font-size: 18px;
      color: #666;
      margin-bottom: 25px;
      word-wrap: break-word;
    }
  
    &__button {
      display: inline-block;
      background-color: $primary-color;
      color: white;
      padding: 12px 25px;
      border-radius: 8px;
      font-weight: 600;
      text-decoration: none;
      transition: all 0.3s ease;
  
      &:hover {
        background-color: darken($primary-color, 10%);
        transform: translateY(-2px);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
      }
    }
  
    &__image-container {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 300px; /* Обмежуємо максимальну ширину */
      width: 100%; /* Забезпечуємо адаптивність */
    }
  
    &__img {
      max-width: 100%;
      height: auto;
      border-radius: 20px;
      box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
      transition: transform 0.5s ease;
      display: block; /* Забираємо додатковий пробіл під зображенням */
  
      &:hover {
        transform: rotate(3deg) scale(1.02);
      }
    }
  }
  
  // Стилі для фільтрів (не змінюються)
  .filter {
    margin-bottom: 30px;
  
    &__container {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  
    &__mobile-toggle {
      display: none;
      align-items: center;
      justify-content: center;
      gap: 10px;
      background-color: #f5f5f5;
      border: none;
      padding: 10px 15px;
      border-radius: 8px;
      cursor: pointer;
      font-weight: 500;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #e9e9e9;
      }
  
      svg {
        width: 20px;
        height: 20px;
      }
    }
  
    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  
    &__top {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }
  
    &__select {
      flex: 1;
      min-width: 150px;
      padding: 12px 15px;
      border: 1px solid #ddd;
      border-radius: 8px;
      background-color: white;
      font-size: 14px;
      appearance: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: calc(100% - 10px) center;
      background-size: 16px;
  
      &:focus {
        outline: none;
        border-color: $primary-color;
        box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
      }
    }
  
    &__reset {
      background-color: #f5f5f5;
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 12px 20px;
      cursor: pointer;
      font-weight: 500;
      transition: all 0.3s ease;
  
      &:hover {
        background-color: #e9e9e9;
      }
    }
  
    &__search {
      position: relative;
      width: 100%;
  
      &-input {
        width: 100%;
        padding: 12px 15px;
        padding-right: 45px;
        border: 1px solid #ddd;
        border-radius: 8px;
        font-size: 14px;
  
        &:focus {
          outline: none;
          border-color: $primary-color;
          box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
        }
      }
  
      &-btn {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
  
        svg {
          color: #666;
          transition: color 0.3s ease;
        }
  
        &:hover svg {
          color: $primary-color;
        }
      }
    }
  }
  
  // Стилі для активних фільтрів (не змінюються)
  .active-filters {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
    flex-wrap: wrap;
  
    &__title {
      font-weight: 500;
      color: #555;
    }
  
    &__tags {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
  
    &__tag {
      display: flex;
      align-items: center;
      background-color: rgba($primary-color, 0.1);
      color: $primary-color;
      padding: 5px 10px;
      border-radius: 6px;
      font-size: 14px;
  
      button {
        background: none;
        border: none;
        cursor: pointer;
        margin-left: 5px;
        color: $primary-color;
        font-size: 16px;
        font-weight: bold;
  
        &:hover {
          color: darken($primary-color, 15%);
        }
      }
    }
  
    &__clear {
      padding: 5px 10px;
      background-color: #f5f5f5;
      border: 1px solid #ddd;
      border-radius: 6px;
      cursor: pointer;
      font-size: 14px;
      transition: all 0.3s ease;
  
      &:hover {
        background-color: #e9e9e9;
      }
    }
  }
  
  // Стилі для порожнього стану (не змінюються)
  .empty-state {
    text-align: center;
    padding: 60px 20px;
    margin: 30px 0;
    background-color: #f9f9f9;
    border-radius: 12px;
  
    h3 {
      margin-bottom: 15px;
      color: #555;
      font-size: 18px;
    }
  
    &__button {
      background-color: $primary-color;
      color: white;
      border: none;
      border-radius: 8px;
      padding: 10px 20px;
      cursor: pointer;
      font-weight: 500;
      transition: all 0.3s ease;
  
      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }
  
  // Стилі для товарів (не змінюються)
  .goods {
    padding: 30px 0 60px;
  
    &__all {
      display: flex;
      justify-content: flex-start; // Вирівнювання по лівому краю
      flex-wrap: wrap;
      gap: 10px; // Менший відступ між товарами
      margin-top: 20px;
    }
  
    .product {
      width: 18%; // Два товари в ряд на мобільних
      margin-bottom: 10px; // Менший відступ знизу
      box-sizing: border-box; // Важливо для правильного розрахунку ширини з урахуванням padding та border
      min-width: 200px;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 8px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      }
    }
  }
  
  // Компонент для відображення кількості товарів (не змінюються)
  .product-count {
    margin-bottom: 20px;
    font-size: 16px;
    color: #555;
  }
  
  /*  Стилі для слайдера (ВІДНОВЛЕНО З ПОПЕРЕДНЬОГО КОДУ) */
  .cart__photos {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .cart__main-photo {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .cart__thumbs {
    width: 100%;
    overflow: hidden;
    margin-top: 10px;
  }
  
  /* Додано контейнер Swiper */
  .swiper-container {
    width: 100%;
    height: auto;
  }
  
  /* Додано обгортку слайдів Swiper */
  .swiper-wrapper {
    display: flex;
    align-items: center;
  }
  
  .cart__img-thumb {
    width: auto; /* Адаптивна ширина */
    max-width: 100px; /* Максимальна ширина */
    height: auto;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 5px;
    object-fit: cover; /* Важливо для правильного масштабування */
  }
  
  .swiper-slide {
    width: auto;
    display: flex;
    align-items: center;
  }
  
  // Медіа-запити для адаптивності
  /* Стилі для екранів до 480px (найменші телефони) */
  @media (max-width: 480px) {
    .goods .product {
      width: 100%; /* Один товар на рядок */
    }
  
    .cart__img-thumb {
      max-width: 70px; /* Адаптивна ширина для малих екранів */
    }
  
      /* Header стилі для екранів до 480px (найменші телефони) */
      .header {
          padding: 30px 0; /* Менший відступ */
          margin-bottom: 20px; /* Менший відступ */
  
          &__wrapper {
              flex-direction: column; /* Вертикальне розташування контенту */
              align-items: center; /* Вирівнювання по центру */
              text-align: center; /* Текст по центру */
          }
  
          &__title {
              font-size: 32px; /* Менший заголовок */
          }
  
          &__subtitle {
              font-size: 18px; /* Менший підзаголовок */
          }
  
          &__text {
              font-size: 16px; /* Менший текст */
          }
  
          &__image-container {
              max-width: 200px; /* Менша максимальна ширина для зображення */
          }
  
          &__img {
              border-radius: 15px; /* Більш заокруглені кути */
          }
      }
  }
  
  /* Стилі для екранів від 481px до 768px (телефони) */
  @media (min-width: 481px) and (max-width: 768px) {
    .goods .product {
      width: 48%; /* Два товари на рядок */
    }
  
    .cart__img-thumb {
      max-width: 80px; /* Адаптивна ширина для середніх екранів */
    }
  
      /* Header стилі для екранів від 481px до 768px (телефони) */
      .header {
          padding: 40px 0; /* Менший відступ */
          margin-bottom: 30px; /* Менший відступ */
  
          &__title {
              font-size: 36px; /* Менший заголовок */
          }
  
          &__subtitle {
              font-size: 20px; /* Менший підзаголовок */
          }
  
          &__image-container {
              max-width: 250px; /* Менша максимальна ширина для зображення */
          }
      }
  }
  
  /* Стилі для екранів більше 768px (планшети та десктопи) */
  @media (min-width: 769px) {
    .goods .product {
      width: 18%; /* П'ять товарів на рядок */
    }
  
    .cart__img-thumb {
      max-width: 100px; /* Адаптивна ширина для великих екранів */
    }
  }

  @media (max-width: 768px) {
    .goods {
        .product {
            width: 100%; /* Розтягуємо на всю ширину на малих екранах */
            min-width: auto; /* Прибираємо мінімальну ширину */
        }
    }
}