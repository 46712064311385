.menu {
    position: fixed; /* або sticky */
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: #161010;
    transition: background-color 0.3s ease;
    height: 80px; /* Визначена висота хедера - підлаштуйте під ваш дизайн */
    
    &--scrolled {
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
  
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      height: 100%;
    }
  
    &__logo {
      display: block;
      z-index: 10;
      transition: transform 0.3s ease;
  
      &:hover {
        transform: scale(1.05);
      }
    }
  
    &__toggle {
      display: none;
      flex-direction: column;
      justify-content: space-between;
      width: 30px;
      height: 21px;
      background: transparent;
      border: none;
      cursor: pointer;
      padding: 0;
      z-index: 10;
  
      &-line {
        width: 100%;
        height: 3px;
        background-color: #CA886A;
        transition: all 0.3s ease;
        border-radius: 3px;
      }
  
      &--active {
        .menu__toggle-line {
          &:first-child {
            transform: translateY(9px) rotate(45deg);
          }
          
          &:nth-child(2) {
            opacity: 0;
          }
          
          &:last-child {
            transform: translateY(-9px) rotate(-45deg);
          }
        }
      }
    }
  
    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      margin-left: 2rem;
    }
  
    &__list {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
    }
  
    &__user {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
    }
  
    &__item {
      margin: 0 0.75rem;
    }
  
    &__link {
      position: relative;
      display: flex;
      align-items: center;
      color: #949393;
      text-decoration: none;
      font-size: 1rem;
      font-weight: 500;
      padding: 0.5rem;
      transition: color 0.3s ease;
  
      &:hover {
        color: #CA886A;
      }
  
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0.5rem;
        right: 0.5rem;
        height: 2px;
        background-color: #CA886A;
        transform: scaleX(0);
        transition: transform 0.3s ease;
        transform-origin: right;
      }
  
      &:hover::after {
        transform: scaleX(1);
        transform-origin: left;
      }
  
      svg {
        margin-right: 0.25rem;
        fill: currentColor;
      }
  
      &--account, &--basket {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        svg {
          margin-right: 0;
          margin-bottom: 0.25rem;
          color: #CA886A;
        }
        
        &:hover svg {
          color: darken(#CA886A, 10%);
        }
      }
    }
  
    &__text {
      font-size: 0.75rem;
    }
  
    // Адаптивні стилі
    @media (max-width: 991px) {
      &__item {
        margin: 0 0.5rem;
      }
      
      &__link {
        font-size: 0.9rem;
      }
    }
  
    @media (max-width: 768px) {
      height: 70px; /* Зменшена висота на мобільних */
      
      &__toggle {
        display: flex;
      }
  
      &__container {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 80%;
        max-width: 300px;
        flex-direction: column;
        justify-content: flex-start;
        background-color: rgb(216, 229, 225);
        margin-left: 0;
        padding: 5rem 1.5rem 2rem;
        transform: translateX(100%);
        transition: transform 0.3s ease;
        box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
        overflow-y: auto;
        z-index: 5;
  
        &--active {
          transform: translateX(0);
        }
      }
  
      &__list {
        flex-direction: column;
        width: 100%;
        margin-bottom: 2rem;
      }
  
      &__user {
        flex-direction: row;
        justify-content: center;
        width: 100%;
        padding-top: 1.5rem;
        border-top: 1px solid #d07070;
      }
  
      &__item {
        margin: 0.5rem 0;
        width: 100%;
        text-align: left;
  
        &:last-child {
          margin-bottom: 0;
        }
      }
  
      &__link {
        width: 100%;
        padding: 0.75rem 0;
        font-size: 1rem;
  
        &::after {
          bottom: 0;
          left: 0;
          right: 0;
        }
  
        &--account, &--basket {
          flex-direction: row;
          justify-content: flex-start;
          
          svg {
            margin-right: 0.75rem;
            margin-bottom: 0;
          }
        }
      }
  
      &__user &__item {
        width: auto;
        margin: 0 1rem;
      }
    }
  
    @media (max-width: 480px) {
      height: 60px; /* Ще менша висота на малих екранах */
      
      &__logo {
        max-width: 80px;
      }
    }
  }
  
  /* Додаємо відступ для всього контенту сторінки */
  body {
    padding-top: 80px; /* Цей відступ має відповідати висоті хедера */
    
    @media (max-width: 768px) {
      padding-top: 70px; /* Відповідає зменшеній висоті хедера на мобільних */
    }
    
    @media (max-width: 480px) {
      padding-top: 60px; /* Відповідає зменшеній висоті хедера на малих екранах */
    }
  }