.basket {
  padding: 40px 0 80px;
  background-color: #f9f9f9;
  min-height: 80vh;

  &__title {
    margin-bottom: 30px;
    text-align: center;
    font-size: 28px;
    font-weight: 600;
  }

  &__steps {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }

  &__step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &-number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: #e0e0e0;
      color: #555;
      font-weight: 600;
      margin-bottom: 8px;
    }

    &-title {
      font-size: 14px;
      color: #555;
    }

    &--active {
      .basket__step-number {
        background-color: #000;
        color: #fff;
      }

      .basket__step-title {
        font-weight: 600;
        color: #000;
      }
    }

    &--completed {
      .basket__step-number {
        background-color: #4CAF50;
        color: #fff;
      }
    }
  }

  &__step-line {
    width: 100px;
    height: 2px;
    background-color: #e0e0e0;
    margin: 0 15px;

    &--active {
      background-color: #4CAF50;
    }
  }

  &__wrapper {
    max-width: 1080px;
    margin: 0 auto;
  }

  &__content {
    display: flex;
    gap: 30px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    &--checkout {
      @media (max-width: 992px) {
        flex-direction: column;
      }
    }
  }

  &__section-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }

  &__products {
    flex: 1;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
    padding: 20px;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__product {
    position: relative;
    padding: 15px 0;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      border-bottom: none;
    }
  }

  &__delete {
    background: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s;

    &:hover svg {
      fill: #ff5252;
    }
  }

  &__sum {
    width: 350px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__summary {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
    padding: 20px;

    &--checkout {
      position: sticky;
      top: 20px;
    }
  }

  &__cost,
  &__delivery,
  &__payable {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    font-size: 15px;
    color: #666;
  }

  &__payable {
    margin-top: 20px;
    padding-top: 12px;
    border-top: 1px solid #eee;
    font-weight: 600;
    color: #000;
    font-size: 16px;
  }

  &__pay {
    display: block;
    width: 100%;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 14px 20px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background-color: #333;
    }

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }

  &__continue-shopping {
    display: block;
    text-align: center;
    margin-top: 15px;
    color: #666;
    text-decoration: underline;
    font-size: 14px;

    &:hover {
      color: #000;
    }
  }

  &__checkout-form {
    flex: 1;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
    padding: 20px;

    @media (max-width: 992px) {
      margin-bottom: 20px;
    }
  }

  &__form {
    &-group {
      margin-bottom: 25px;
    }

    &-row {
      display: flex;
      gap: 15px;
      margin-bottom: 15px;

      @media (max-width: 576px) {
        flex-direction: column;
        gap: 15px;
      }
    }

    &-field {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-bottom: 15px;

      label {
        font-size: 14px;
        margin-bottom: 6px;
        color: #555;
      }

      input,
      textarea {
        padding: 12px 15px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 15px;
        transition: all 0.2s;

        &:focus {
          border-color: #000;
          outline: none;
        }

        &::placeholder {
          color: #aaa;
        }
      }

      textarea {
        min-height: 100px;
        resize: vertical;
      }
    }

    &-error {
      border-color: #ff5252 !important;
    }
  }

  &__error-message {
    color: #ff5252;
    font-size: 12px;
    margin-top: 5px;
    display: block;
  }

  &__order-products {
    margin-bottom: 20px;
    max-height: 250px;
    overflow-y: auto;
    padding-right: 5px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 10px;
    }
  }

  &__order-product {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: none;
    }

    &-info {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &-image {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 4px;
    }

    &-title {
      font-size: 14px;
      margin-bottom: 4px;
    }

    &-size {
      font-size: 12px;
      color: #888;
    }

    &-price {
      font-weight: 500;
    }
  }

&__order-product-price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

&__order-product-old-price {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: line-through;
  margin-bottom: 4px;
}

  &__checkout-buttons {
    display: flex;
    gap: 10px;

    .basket__pay {
      flex: 1;
    }
  }

  &__back-button {
    display: block;
    background-color: transparent;
    color: #666;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 14px 20px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background-color: #f5f5f5;
      border-color: #ccc;
    }
  }

  &__empty {
    text-align: center;
    padding: 60px 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);

    &-icon {
      margin: 0 auto 20px;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #f5f5f5;
    }

    &-text {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 10px;
      color: #333;
    }

    &-subtext {
      color: #666;
      margin-bottom: 25px;
    }
  }

  &__shop {
    display: inline-block;
    background-color: #000;
    color: #fff;
    padding: 14px 30px;
    border-radius: 4px;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.2s;

    &:hover {
      background-color: #333;
    }
  }

  /* Стилі для модального вікна успішного замовлення */
  .order-success {
    text-align: center;
    padding: 30px 20px;

    &__icon {
      margin: 0 auto 20px;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #f0fdf0;
    }

    &__title {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 15px;
      color: #333;
    }

    &__text {
      color: #666;
      margin-bottom: 25px;
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
    }

    &__button {
      display: inline-block;
      background-color: #4CAF50;
      color: #fff;
      border: none;
      padding: 14px 30px;
      border-radius: 4px;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        background-color: #3d9140;
      }
    }
  }

  /* Вдосконалення стилів для компонентів модального вікна */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    &__content {
      background-color: #fff;
      border-radius: 8px;
      max-width: 500px;
      width: 90%;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
      animation: modalFadeIn 0.3s;
    }

    &__close {
      position: absolute;
      top: 15px;
      right: 15px;
      background: none;
      border: none;
      cursor: pointer;
      color: #888;
      transition: all 0.2s;

      &:hover {
        color: #000;
      }
    }
  }

  @keyframes modalFadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /*  Стилі для autocomplete*/
  &__autocomplete-wrapper {
    position: relative;
    width: 100%;

    input {
      width: 100%;
      padding: 10px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;

      &:focus {
        outline: none;
        border-color: #888;
      }
    }
  }

  &__autocomplete-suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 200px;
    overflow-y: auto;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-top: none;
    border-radius: 0 0 4px 4px;
    z-index: 10;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__autocomplete-suggestion {
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  &__autocomplete-loader {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    .spinner {
      width: 16px;
      height: 16px;
      border: 2px solid rgba(0, 0, 0, 0.1);
      border-top-color: #888;
      border-radius: 50%;
      animation: spin 0.8s linear infinite;
    }
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  &__form-error {
    border-color: #ff6b6b !important;
  }

  /* Медіа-запити для адаптивності */
  @media (max-width: 992px) {
    .basket__wrapper {
      padding: 0 15px;
    }
  }

  @media (max-width: 768px) {
    padding: 30px 0 60px;

    &__title {
      margin-bottom: 20px;
      font-size: 24px;
    }

    &__section-title {
      font-size: 16px;
    }
  }

  @media (max-width: 576px) {
    &__step {
      &-title {
        font-size: 12px;
      }

      &-number {
        width: 30px;
        height: 30px;
      }
    }

    &__step-line {
      width: 60px;
    }
  }
}