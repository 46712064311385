.pagination-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.25rem;
  width: 100%;
  padding: 1.25rem;
  box-sizing: border-box;
  transition: opacity 0.3s ease;
  
  /* Мобільна версія для маленьких екранів (включаючи 393px) */
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* Рівно 2 товари в рядку */
    gap: 0.75rem; /* Зменшуємо відступи між товарами */
    padding: 0.75rem; /* Зменшуємо внутрішні відступи для маленьких екранів */
  }
  
  /* Для екранів менше 375px - лише один товар в рядку */
  @media (max-width: 375px) {
    grid-template-columns: 1fr; /* Один товар в рядку */
  }
  
  /* Десктопна версія: 5 товарів в рядку */
  @media (min-width: 1200px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

/* Клас для анімації згасання */
.products-grid-fade-enter {
  opacity: 0;
}

.products-grid-fade-enter-active {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.pagination {
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  margin-top: 1.25rem;
  padding: 0 0.625rem;
  list-style: none;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  
  &__page,
  &__prev,
  &__next,
  &__break {
    padding: 0.5rem 0.75rem;
    margin: 0 0.1875rem;
    cursor: pointer;
    border-radius: 0.25rem;
    text-decoration: none;
    color: #212529;
    transition: all 0.2s ease-in-out;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    
    &:hover {
      background-color: #f0f0f0;
    }
  }
  
  &__page--active {
    background-color: #d1e4f0;
    color: black;
    font-weight: bold;
    box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.2);
  }
  
  &__prev,
  &__next {
    &:disabled {
      color: gray;
      cursor: default;
      opacity: 0.5;
      
      &:hover {
        background-color: transparent;
      }
    }
    
    &--hidden {
      display: none;
    }
  }
  
  /* Для десктопної версії центруємо пагінацію */
  @media (min-width: 1200px) {
    justify-content: center;
    overflow-x: unset;
  }
}