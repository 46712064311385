// home.scss

// Змінні для кольорів та інших значень
:root {
    --primary-color: #4A4A4A;
    --accent-color: #FF6B6B;
    --text-color: #333;
    --light-bg: #F8F9FA;
    --border-radius: 8px;
    --container-padding: 20px;
    --transition: all 0.3s ease;
  }
  
  // Основні стилі для контейнера
  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 var(--container-padding);
  }
  
  // Загальні стилі заголовків
  .section-title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 24px;
    position: relative;
    padding-bottom: 10px;
    
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 60px;
      height: 3px;
      background-color: var(--accent-color);
    }
  }
  
  // Стилі для кнопок
  .btn-primary {
    display: inline-block;
    background-color: var(--accent-color);
    color: white;
    padding: 12px 24px;
    font-weight: 600;
    border-radius: var(--border-radius);
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: var(--transition);
    
    &:hover {
      background-color: darken(#FF6B6B, 10%);
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  }
  
  // Стилі для хедера
  .header {
    padding: 60px 0;
    background-color: var(--light-bg);
    margin-bottom: 40px;
    
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
    }
    
    &__content {
      flex: 1;
    }
    
    &__title {
      font-size: 42px;
      font-weight: 800;
      margin-bottom: 16px;
      color: var(--primary-color);
    }
    
    &__text {
      font-size: 18px;
      margin-bottom: 24px;
      line-height: 1.6;
      color: var(--text-color);
    }
    
    &__shop {
      margin-top: 10px;
    }
    
    &__image-container {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    &__img {
      max-width: 100%;
      height: auto;
      border-radius: var(--border-radius);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
      transition: var(--transition);
      
      &:hover {
        transform: scale(1.02);
      }
    }
  }
  
  // Стилі для секції популярних товарів
  .popular {
    margin-bottom: 60px;
    
    &__title {
      text-align: left;
    }
    
    &__products {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
      gap: 20px;
      margin-bottom: 30px;
    }
    
    // Стилі для кнопки "Переглянути повний каталог"
    &__view-all {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  
  // Стилі для кнопки "Переглянути повний каталог"
  .btn-view-all {
    display: inline-block;
    background-color: var(--primary-color);
    color: white;
    padding: 12px 30px;
    font-weight: 600;
    border-radius: var(--border-radius);
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: var(--transition);
    margin: 10px auto;
    text-align: center;
    
    &:hover {
      background-color: var(--accent-color);
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  }
  
  // Стилі для секції розпродажу
  .sale {
    background-color: var(--light-bg);
    padding: 40px 0;
    margin-bottom: 60px;
    
    &__title {
      text-align: left;
    }
    
    &__wrapper {
      margin-top: 30px;
      position: relative; // Виправлення для слайдера
      
      // Фіксація проблеми зі слайдером
      .swiper-scrollbar {
        position: relative !important;
        bottom: 0 !important;
        margin-top: 15px;
      }
    }
  }
  
  // Стилі для сітки продуктів
  .products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 20px;
  }
  
  // Стилі для завантаження
  .loading-container, .no-sale-container {
    padding: 30px;
    text-align: center;
    background-color: white;
    border-radius: var(--border-radius);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  // Додатково для коректної роботи swiper-слайдера
  .swiper-container {
    overflow: hidden;
    position: relative;
  }
  
  // Виправлення для swiper-scrollbar
  .swiper-scrollbar-horizontal {
    position: relative !important;
    bottom: 0 !important;
    left: 0 !important;
    z-index: 50;
    height: 5px !important;
    margin-top: 20px !important;
  }
  
  // Медіа-запити для адаптивності
  @media (max-width: 1200px) {
    .header {
      padding: 50px 0;
      
      &__title {
        font-size: 36px;
      }
    }
    
    .section-title {
      font-size: 24px;
    }
  }
  
  @media (max-width: 992px) {
    .header {
      &__wrapper {
        flex-direction: column;
        text-align: center;
      }
      
      &__title {
        font-size: 32px;
      }
      
      &__content {
        order: 2;
      }
      
      &__image-container {
        order: 1;
        margin-bottom: 30px;
      }
    }
    
    .section-title {
      text-align: center;
      
      &:after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  
  @media (max-width: 768px) {
    .header {
      padding: 40px 0;
      
      &__title {
        font-size: 28px;
      }
      
      &__text {
        font-size: 16px;
      }
    }
    
    .container {
      padding: 0 15px;
    }
    
    .popular, .sale {
      margin-bottom: 40px;
    }
    
    .products-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 576px) {
    .header {
      padding: 30px 0;
      
      &__title {
        font-size: 24px;
      }
      
      &__shop {
        width: 100%;
        display: block;
        text-align: center;
      }
    }
    
    .products-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
    
    .btn-view-all {
      width: 100%;
      padding: 12px 20px;
    }
  }